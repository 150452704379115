import React from 'react';
import Layout from '../components/Layouts/Layout';
import Hero from '../components/Hero';
import SubHero from '../components/subHero';
import SEO from '../components/seo';
import Events from '../components/Events/Events';
import TempleUpdates from '../components/TempleUpdates/TempleUpdates';
import Carousel from '../components/Carousel/Carousel';
import ThakorjiDarshan from '../components/ThakorjiDarshan';
// import AnnouncementModal from '../components/Modals/AnnouncementModal';

const IndexPage = () => (
  <Layout>
    <SEO title='Home' />
    <main className='bg-orange-200'>
      <div className='custom-width-carousel'>
        <Carousel />
      </div>
      {/* <Hero /> */}
      {/* <SubHero /> */}
      <Events />
      <ThakorjiDarshan />
      <TempleUpdates />
      {/* <AnnouncementModal /> */}
    </main>
  </Layout>
);

export default IndexPage;
