import React from 'react';
import useTempleUpdates from './queries/useTempleUpdates';

export default function TempleUpdates() {
  const templeUpdatesData = useTempleUpdates();
  return (
    <section className='bg-white'>
      <div className='container mx-auto text-center justify-center px-4'>
        <div className='flex flex-wrap justify-center text-center mb-12'>
          <div className='w-full lg:w-6/12 px-4'>
            <h4 className='text-4xl font-semibold leading-normal mt-0 text-orange-500'>
              Temple Updates
            </h4>
            <div className='flex items-center justify-center'>
              <hr className='my-2 border-gray-400 w-1/2' />
            </div>
          </div>
        </div>
        <div className='flex flex-wrap justify-center'>
          {templeUpdatesData.map(data => (
            <div
              className='w-full md:w-6/12 lg:w-6/12 lg:mb-0 mb-12 px-4'
              key={data.id}
            >
              <div className='px-6'>
                <div className='iframe-container'>
                  <iframe
                    title={`Vlog ${data.vlog}`}
                    width='560'
                    height='315'
                    src={data.src}
                    frameBorder='0'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                    allowFullScreen
                  />
                </div>
                <div className='py-6 text-center'>
                  <h5 className='text-xl font-bold'>{data.title}</h5>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
