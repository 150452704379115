import { graphql, useStaticQuery } from 'gatsby';

export default function useTempleUpdates() {
  const { allTempleUpdatesJson } = useStaticQuery(
    graphql`
      query {
        allTempleUpdatesJson {
          nodes {
            src
            title
            vlog
            id
          }
        }
      }
    `
  );
  return allTempleUpdatesJson.nodes;
}
