import React from 'react';
import Slider from 'react-slick';
import { GatsbyImage } from 'gatsby-plugin-image';
import useHeros from './queries/useHeros';

const settings = {
  dots: true,
  infinite: true,
  autoplay: true,
  speed: 2000,
  autoplaySpeed: 8000,
};

export default function Carousel() {
  const herosData = useHeros();
  return (
    <Slider {...settings} className='pt-20'>
      {herosData.map(data => (
        <div key={data.alt}>
          <GatsbyImage
            image={data.src.childImageSharp.gatsbyImageData}
            alt={data.alt}
          />
        </div>
      ))}
    </Slider>
  );
}
