import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import useRecentEvent from './queries/useRecentEvent';
import usePastEvent from './queries/usePastEvent';
import useWeeklySabha from './queries/useWeeklySabha';

export default function Events() {
  const {
    title: recentEventTitle,
    date: recentEventDate,
    src: recentEventImg,
    path: recentEventPath,
  } = useRecentEvent();
  const {
    title: weeklySabhaTitle,
    date: weeklySabhaDate,
    src: weeklySabhaImg,
  } = useWeeklySabha();
  const {
    title: pastEventTitle,
    date: pastEventDate,
    src: pastEventImg,
    path: pastEventPath,
  } = usePastEvent();

  return (
    <section
      className='py-10 bg-gray-300'
      style={{
        marginTop: '-8px',
      }}
    >
      <div className='container mx-auto px-4'>
        <div className='flex flex-wrap items-center justify-center'>
          <div className='w-full md:w-4/12 px-4'>
            <div className='relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg text-center'>
              <div className='px-4 py-5 flex-auto'>
                <span className='text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-red-600 bg-orange-200 uppercase last:mr-0 mr-1'>
                  Darshan Timings
                </span>
                <div className='flex flex-wrap justify-center mt-2'>
                  <GatsbyImage
                    image={recentEventImg.childImageSharp.gatsbyImageData}
                    style={{ height: '100%', width: '100%' }}
                    className='shadow-lg rounded align-middle border-none'
                    alt='Darshan Timings'
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='w-full md:w-4/12 px-4 text-center'>
            <div className='relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg'>
              <div className='px-4 py-5 flex-auto'>
                <span className='text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-red-600 bg-orange-200 uppercase last:mr-0 mr-1'>
                  Mandir Address
                </span>
                <div className='flex flex-wrap justify-center mt-3'>
                  <GatsbyImage
                    image={weeklySabhaImg.childImageSharp.gatsbyImageData}
                    style={{ height: '100%', width: '100%' }}
                    className='shadow-lg rounded align-middle border-none'
                    alt='Mandir Address'
                  />
                </div>
                <hr className='my-4 border-gray-400' />
                <p className='mt-2 mb-2 text-gray-700'>
                  <strong>540 South Martingale Road</strong>
                </p>
                <p className='mb-4 text-gray-700'>
                  <strong>Schaumburg, IL 60193</strong>
                </p>
                <hr className='my-4 border-gray-400' />
                <p className='mt-2 mb-4 text-gray-600'>
                  Tel:{' '}
                  <strong>
                    <a href='tel:8473805168'>(847) 380 5168</a>
                  </strong>{' '}
                </p>
                <p className='mt-2 mb-4 text-gray-600'>
                  Email:{' '}
                  <strong>
                    <a href='mailto:temple@ydschicago.org'>
                      temple@ydschicago.org
                    </a>
                  </strong>{' '}
                </p>
              </div>
            </div>
          </div>

          <div className='w-full md:w-4/12 px-4 text-center'>
            <div className='relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg'>
              <div className='px-4 py-5 flex-auto'>
                <span className='text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-red-600 bg-green-200 uppercase last:mr-0 mr-1'>
                  Upcoming Events
                </span>
                <h6 className='text-xl font-semibold my-2'>
                  No Upcoming Events.
                </h6>
                {/* <div className='py-3 text-center'>
                  <h6 className='font-semibold mb-2'>March 24th, 2024</h6>
                  <span className='font-semibold'>Holi & Fuldol Utsav</span>
                  <p>
                    Bhajan & Sabha <br /> <span>5:00 PM - 7:00 PM</span>
                  </p>
                  <p>
                    Aarti & Mahaprasad <br /> <span>7:00 PM - 8:00 PM</span>
                  </p>
                  <p>
                    Holi Rangotsav <br /> <span>8:00 PM (Parking Lot)</span>
                  </p>
                </div>
                <Link
                  to='/events'
                  className='bg-orange-500 text-white active:bg-orange-600 font-semibold px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150'
                >
                  View Details
                </Link> */}
              </div>
              <hr className='my-2 border-gray-400' />
              <div className='px-4 py-5 flex-auto'>
                <span className='text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-red-600 bg-red-200 uppercase last:mr-0 mr-1'>
                  Recent Event
                </span>
                <h6 className='text-xl font-semibold my-2'>{pastEventTitle}</h6>
                <span className='text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-red-600 bg-red-200 uppercase last:mr-0 mr-1'>
                  {pastEventDate}
                </span>
                <div className='flex flex-wrap justify-center mt-2'>
                  <GatsbyImage
                    image={pastEventImg.childImageSharp.gatsbyImageData}
                    style={{ height: '100%', width: '100%' }}
                    className='shadow-lg rounded align-middle border-none'
                    alt={pastEventTitle}
                  />
                </div>
                <div className='flex flex-wrap justify-center mt-3'>
                  {/* <a
                    href='https://www.youtube.com/embed/8dvW7K6ixHE'
                    target='_blank'
                    className='text-orange-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                    rel='noreferrer'
                  >
                    <i className='fas fa-play' /> Watch
                  </a> */}
                  <Link
                    to={`/gallery/${pastEventPath}`}
                    className='text-orange-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                  >
                    <i className='fas fa-camera' /> Photos
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
