import { graphql, useStaticQuery } from 'gatsby';

export default function useHeros() {
  const { allHerosJson } = useStaticQuery(
    graphql`
      query {
        allHerosJson {
          nodes {
            alt
            src {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    `
  );
  return allHerosJson.nodes;
}
