import { graphql, useStaticQuery } from 'gatsby';

export default function useRecentEvent() {
  const { markdownRemark } = useStaticQuery(
    graphql`
      {
        markdownRemark(frontmatter: { slug: { eq: "Recent Event" } }) {
          frontmatter {
            date
            title
            path
            src {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    `
  );
  return markdownRemark.frontmatter;
}
