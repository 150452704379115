import axios from 'axios';
import React, { useEffect, useState } from 'react';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';

const ThakorjiDarshan = () => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const fetchImages = await axios({
        method: 'get',
        url: 'https://api.flickr.com/services/rest',
        params: {
          method: 'flickr.photosets.getPhotos',
          api_key: '0402b0acfa4fb3ffaf9d3f4dc6824c46',
          photoset_id: '72177720311913648',
          extras: 'url_n, owner_name, date_taken, views',
          page: 1,
          format: 'json',
          nojsoncallback: 1,
        },
      });

      const { data: result } = fetchImages;
      const picsArray = result.photoset.photo.map(
        pic =>
          `https://farm${pic.farm}.staticflickr.com/${pic.server}/${pic.id}_${pic.secret}_b.jpg`
      );
      setImages(picsArray);
    };
    fetchData();
  }, []);

  return (
    <SimpleReactLightbox>
      <section className='py-16 bg-white'>
        <div className='container mx-auto text-center justify-center px-4'>
          <div className='flex flex-wrap justify-center text-center mb-12'>
            <div className='w-full lg:w-6/12 px-4'>
              <h4 className='text-4xl font-semibold leading-normal mt-0 text-orange-500'>
                Thakorji Darshan
              </h4>
              <div className='flex items-center justify-center'>
                <hr className='my-2 border-gray-400 w-1/2' />
              </div>
            </div>
          </div>
          <div className='items-center justify-center flex flex-wrap'>
            <SRLWrapper>
              <div className='image-container'>
                {images.map(image => (
                  <div>
                    <a href={image}>
                      <img src={image} alt='Thakorji Darshan' />
                    </a>
                  </div>
                ))}
              </div>
            </SRLWrapper>
          </div>
        </div>
      </section>
    </SimpleReactLightbox>
  );
};

export default ThakorjiDarshan;
